import { postAnalytics, recordInteraction } from "../../utilities/analytics";
import { eventDelegation } from "../../utilities/common";
import { scrollIntoViewIfNeeded } from "../scroll-logic/browser";
import { remoteComponentPublish } from "../remote-trigger/browser";
import { geolocate } from "../../utilities/geolocate";

//Handles freezing the body of the page
export const enableScroll = (scrollPosition) => {
  const body = document.querySelector("body");
  body.style.removeProperty("overflow");
  body.style.removeProperty("position");
  body.style.removeProperty("top");
  body.style.removeProperty("width");
  body.style.removeProperty("left");
  window.scrollTo(0, scrollPosition);
};

export const disableScroll = (scrollPosition) => {
  const body = document.querySelector("body");
  const left = body.getBoundingClientRect().left;
  body.style.left = left + "px";
  body.style.overflow = "hidden";
  body.style.position = "fixed";
  body.style.top = `-${scrollPosition}px`;
  body.style.width = "100%";
};

// Adds generic component interaction analytics to any components with the `use-analytics` attribute
eventDelegation("body", "click", "[use-analytics]", (event) => {
  const clickedElement = event.target;
  const link = clickedElement.closest("a[href]");
  const linkObject = getLinkObj(link);

  if (linkObject !== undefined && Object.keys(linkObject).length === 0) {
    recordInteraction(
      clickedElement,
      // Passing in an empty string so that the findInteractionText will dynamically grab the proper text
      "",
      "click"
    );
  }
});

eventDelegation("body", "click", "a[href]", (event) => {
  const linkElement = event.target.closest("a[href]");
  const linkObject = getLinkObj(linkElement);

  if (linkObject !== undefined && Object.keys(linkObject).length !== 0) {
    const { link, fileName, fileType } = linkObject;
    let mediaType = fileType;
    switch (fileType) {
      case "png":
      case "jpeg":
        mediaType = "Image";
        break;
      case "mp4":
        mediaType = "Video";
        break;
      case "pdf":
        mediaType = "PDF";
        break;
      default:
        mediaType = fileType;
        break;
    }
    postAnalytics({
      event: window.digitalDataEventsCUC.component.download,
      component: {
        info: {
          name: fileName,
          link
        },
        category: {
          primary: mediaType
        }
      }
    });
  } else if (!linkElement.closest("[use-analytics]")) {
    recordInteraction(
      linkElement,
      // Passing in an empty string so that the findInteractionText will dynamically grab the proper text
      "",
      "click"
    );
  }
});

function getLinkObj(element) {
  const fileTypes = ["png", "jpeg", "mp4", "pdf", "zip"];
  if (element) {
    const link = element.href;
    const linkParts = link.split(".");
    const lastSegment = linkParts[linkParts.length - 1]; // grabs the file type from the last part of the URL
    const fileType = fileTypes.find((fileType) =>
      lastSegment.includes(fileType)
    );
    if (fileType) {
      const smallerLinkparts = linkParts[linkParts.length - 2].split("/");
      const fileName = smallerLinkparts[smallerLinkparts.length - 1];
      return link &&
        fileType &&
        fileName &&
        !element.hasAttribute("data-remotetriggerid")
        ? { link, fileName, fileType }
        : {};
    }
  } else return {};
}

// Moved country and phone country's geolocate to page load to reduce the number of calls to Mapservices.
window.addEventListener("load", () => {
  let countryFields = document.getElementsByName("country");
  let phoneDropDowns = document.getElementsByClassName("phone-field_select");
  if (countryFields?.length > 0 || phoneDropDowns?.length > 0) {
    geolocate().then((generalLocation) => {
      if (generalLocation?.address?.countryCode3) {
        Array.from(countryFields)?.forEach((countryField) => {
          const countryOption = countryField.querySelector(
            `[data-country-alpha3=${generalLocation?.address?.countryCode3}]`
          );
          if (countryOption) {
            countryField.value = countryOption.value;
            countryField.dispatchEvent(new Event("change", { bubbles: true }));
          } else countryField.selectedIndex = 0;
        });
        Array.from(phoneDropDowns)?.forEach((phoneDropDown) => {
          phoneDropDown.value = generalLocation?.address?.countryCode3;
          const newCountryIndex = phoneDropDown.selectedIndex;
          const newCountryOption = phoneDropDown.options[newCountryIndex];
          const parent = phoneDropDown.parentNode;
          const flagDisplay = parent.querySelector(
            ".phone-field_displayedFlag"
          );
          if (flagDisplay)
            flagDisplay.style = newCountryOption?.getAttribute("style");
          const phoneCountryCode = parent.querySelector(
            '[name="phoneCountryCode"]'
          );
          if (phoneCountryCode)
            phoneCountryCode.value = newCountryOption?.dataset?.phoneCode;

          const phoneCountry = parent.querySelector('[name="phoneCountry"]');
          if (phoneCountry)
            phoneCountry.value = newCountryOption?.dataset?.value;

          const phoneCountryAlpha3 = parent.querySelector(
            '[name="phoneCountryAlpha3"]'
          );
          if (phoneCountryAlpha3)
            phoneCountryAlpha3.value = newCountryOption?.dataset?.alpha3;

          const customWhitelist = parent.querySelector(
            '[name="customWhitelist"]'
          );
          if (customWhitelist)
            customWhitelist.value =
              newCountryOption?.hasAttribute("in-whitelist");

          const countryCodeOutput = parent.querySelector(
            "[country-code-output]"
          );
          if (countryCodeOutput)
            countryCodeOutput.innerHTML = newCountryOption?.dataset?.phoneCode;

          const reqForm = parent.closest("form");
          let confirmationPrefComponent = reqForm?.querySelector(
            '[data-type="confirmation-pref"]'
          );
          if (confirmationPrefComponent) {
            confirmationPrefComponent.querySelector(
              '[value="SMS"]'
            ).disabled = true;
            confirmationPrefComponent
              .querySelector('[value="SMS"]')
              .removeAttribute("checked");
            confirmationPrefComponent.querySelector(
              '[value="SMS"]'
            ).checked = false;
            confirmationPrefComponent.querySelector(
              '[value="EMAIL"]'
            ).checked = true;
          }
        });
      }
    });
  }
});

// delegated event to only add one per page
document.body.addEventListener("click", (event) => {
  // look for the closest anchor that start with a #
  const hashLink = event.target.closest("[href^='#']");

  // if we find one, it is an in-page link... so go ahead and do stuff
  if (hashLink) {
    const id = hashLink.getAttribute("href").replace("#", "");

    // if the linked ID is in the remoteComponentSubscribeList, then we must be trying to fire a remoteTriggerID via a text block link
    if (window.remoteComponentSubscribeList.includes(id)) {
      remoteComponentPublish(id, event);

      // don't follow the hash since we are using it to trigger the remote component
      event.preventDefault();

      // shouldn't do the rest of this click event to smooth scroll, so return
      return;
    }

    // if they are ok with motion, do animation. Otherwise abort and just do default jump
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      event.preventDefault();
      const theHash = hashLink.getAttribute("href");
      const scrollingTo = document.querySelector(theHash);

      if (scrollingTo) {
        // need to add a tabindex if needed so we can put focus there
        if (!scrollingTo.getAttribute("tabindex")) {
          scrollingTo.setAttribute("tabindex", "-1");
        }

        // can't focus() until the scroll is done. So setup a watcher
        var scrollTimeout;
        const scrollWatcher = () => {
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            // scroll done, focus it
            scrollingTo.focus();
            // remove the scrollWatcher
            window.removeEventListener("scroll", scrollWatcher);
          }, 500);
        };
        window.addEventListener("scroll", scrollWatcher);
        // scroll it
        scrollIntoViewIfNeeded(scrollingTo, {
          behavior: "smooth"
        });
        // because we did preventDefault to prevent scroll we also prevented the URL hash from updating. So update it another way. But only if it isn't already the current hash, otherwise it potentially stacks the history with the hash multiple times
        if (theHash !== window.location.hash)
          window.history.pushState(null, null, theHash);
      }
    }
  }
});
