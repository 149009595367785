const descriptionClass = "typography_description";
import styles from "./styles.css";
import placeholders from "./placeholders";
import Drawers from "../drawers/browser";
import CheckBox from "../form-components/check-box/browser";
import EmailField from "../form-components/email-field/browser";
import PhoneField from "../form-components/phone-field/browser";
import OfferSelector from "../form-components/offer-selector/browser";
import SelectGroup from "../form-components/select-group/browser";
import SteppedForm from "../stepped-form/browser";
import { setUpEnhancedSlider } from "../enhanced-ui-slider/browser";
import { setupPlayer } from "../media-player/browser";
import { unveil } from "../unveil/browser";
import { getParam, makeGuid, initComponent } from "../../utilities/common";
import SVG from "../svg/browser";
import Icon from "../icon/browser";
import {
  remoteComponentBind,
  triggerAttribute
} from "../remote-trigger/browser";
import cookies from "../cookies/browser";
import { scrollIntoViewIfNeeded } from "../scroll-logic/browser";
import customEvent from "../custom-event/browser";
import { Dependencies } from "../form-components/display-dependency/browser";
import { recordInteraction } from "../../utilities/analytics";
// TODO: all of these imports should be replaced by a window.cuc.reInit function when it's ready

export default class ChurchProductResults {
  constructor(element) {
    this.element = element;

    this.singleResultTemplate = element.querySelector(
      "[single-result-template]"
    );
    this.multipleResultTemplate = element.querySelector(
      "[multiple-result-template]"
    );
    this.gmDetailsTemplate = element.querySelector("[gm-details-template]");
    this.socialButtonTemplate = element.querySelector(
      "[social-button-template]"
    );
    this.formTemplate = element.querySelector("[form-template]").innerHTML;
    this.directionsIcon = element.querySelector(
      "[directions-icon-template]"
    ).innerHTML;
    const translatedStrings = element.querySelector(
      "[translated-strings]"
    ).innerHTML;
    this.translatedStrings = JSON.parse(translatedStrings);
    this.resultsContainer = element.querySelector(
      '[data-type="results-container"]'
    );
    this.twoCharLocale = element.getAttribute("two-char-locale");
    this.countryCode = element.getAttribute("country-code");
    const hourCycle = element.getAttribute("hour-cycle");
    /* this.hourCycle must be either undefined or a valid string */
    this.hourCycle =
      !hourCycle || hourCycle === "undefined" ? undefined : hourCycle;
    this.formDisplay = element.getAttribute("form-display");
    const campaignsAndUnits = element.querySelector(
      "[units-and-campaigns]"
    ).innerHTML;
    const { campaignIds, unitIds } = JSON.parse(
      decodeURIComponent(campaignsAndUnits)
    );
    this.campaignIds = campaignIds;
    this.unitIds = unitIds;
    this.cidCookieVal = cookies.get("CID"); // get boncom campaign
    this.resultsContainer.addEventListener("click", (e) => this.clickResult(e));
    this.initialSelectedUnitParam = getParam("unit");
    this.givingMachineVariant =
      this.gmDetailsTemplate && element.dataset.isDefault === "false";
    this.resetSVGs();

    if (this.givingMachineVariant) {
      // Moves side view results on top of the map
      const map = this.element
        ?.closest("[data-type='church-product']")
        ?.querySelector("[data-type='church-product-map']");
      if (map) {
        map.insertBefore(this.element, map.firstChild);
      }
    }
  }

  static capitalize(string) {
    return (string || "")
      .toLowerCase()
      .replace(/\b([a-z])/g, (match) => match.toUpperCase());
  }

  clickResult(e) {
    const opener = e.target.closest("[data-church-unit-id]") || e.target;
    const btn = opener.closest("button");
    const churchUnitId = opener.dataset.churchUnitId;
    const locationIndex = opener.dataset.locationIndex;
    const criteria = btn && churchUnitId && locationIndex;
    if (criteria && btn.getAttribute("aria-expanded") === "false")
      this.selectUnit(churchUnitId, locationIndex);
    else if (criteria && btn.getAttribute("aria-expanded") === "true")
      this.selectUnit(null, null);
  }

  static displayPhoneNumber(result) {
    const { missionaryPhoneNumbers = [] } = result;
    return missionaryPhoneNumbers?.[0] || "";
  }

  fireEvent(eventString, data = null) {
    customEvent(this.element, eventString, {
      bubbles: true,
      detail: { data }
    });
  }

  highlightResults(locationIndex) {
    if (this.givingMachineVariant) {
      this.toggleGmDetails(locationIndex);
    } else {
      this.resultsContainer
        .querySelectorAll(`[data-location-index]`)
        .forEach((el) => {
          const currLocationIndex = parseInt(el.dataset.locationIndex);
          const currentDrawer = el.closest('[data-type="drawers-container"]');

          if (currLocationIndex === locationIndex || locationIndex === null) {
            // Highlights corresponding results
            currentDrawer.setAttribute("data-result-highlight", "");
            currentDrawer.style.display = "";
          } else {
            // clears results that do not correspond
            currentDrawer.removeAttribute("data-result-highlight");
            currentDrawer.style.display = "none";
          }
        });
    }
    scrollIntoViewIfNeeded(this.resultsContainer, {
      behavior: "smooth",
      block: "center"
    });
  }

  toggleGmDetails(index) {
    const container = this.resultsContainer.querySelector(
      `.${styles.givingMachineDetails}`
    );
    const isOpen = container.dataset.isOpen === "true";
    const results = [
      ...this.resultsContainer.querySelectorAll(`.${styles.information}`)
    ];
    const active = results.find((result) => result.dataset.isActive === "true");
    const selected = results.find((result) => Number(result?.id) === index);

    // If selected is already active - do nothing
    if (active === selected) return;

    if (isOpen && !index) {
      // If there is no index: close it and remove the old active
      container.dataset.isOpen = false;
      setTimeout(() => {
        if (active) active.dataset.isActive = false; // Delayed so user doesn't see it change as it transitions off screen
      }, 500);
    } else if (selected !== active) {
      // If selected is different: remove the old active, set new active, and open it up
      if (active) active.dataset.isActive = false;
      selected.dataset.isActive = true;
      if (!isOpen) container.dataset.isOpen = true;
    }
  }

  initNewComponents() {
    const components = [
      ["drawers", (el) => new Drawers(el)],
      ["stepped-form", (el) => new SteppedForm(el, "Church Product Form")],
      [
        "check-box",
        (el) => {
          /* This is important to make sure the ids for labels and inputs line up */
          const guid = makeGuid();
          const checkbox = el.querySelector('input[type="checkbox"]');
          checkbox.id = guid;
          checkbox.closest("label").htmlFor = guid;
          new CheckBox(el);
        }
      ],
      ["email-field", (el) => new EmailField(el)],
      ["phone-field", (el) => new PhoneField(el)],
      ["offer-selector", (el) => new OfferSelector(el)],
      ["select-group", (el) => new SelectGroup(el)],
      ["enhanced-ui-slider", (el) => setUpEnhancedSlider(el)],
      ["media-player", (el) => setupPlayer(el)],
      ["unveil", (el) => unveil(el), '[data-unveil="true"]'],
      ["svg", (el) => new SVG(el), '[data-type="svg"]'],
      ["icon", (el) => new Icon(el), "[data-icon-name]"],
      [
        "remote-trigger",
        (el) => remoteComponentBind(el),
        `[data-${triggerAttribute}]`
      ]
    ];
    components.forEach((c) => initComponent(...c));
    this.displayDependencies?.unwatch();
    this.displayDependencies = new Dependencies(this.resultsContainer).watch();
  }

  makeAddressStr(result) {
    const line1 = `${result?.address?.street1 || ""} ${
      result?.address?.street2 || ""
    }`.trim();
    const stateOrCounty =
      ChurchProductResults.capitalize(result?.address?.state || "") ||
      ChurchProductResults.capitalize(result?.address?.county || "");
    const cityAndRegion = [
      ChurchProductResults.capitalize(result?.address?.city || "") || "",
      stateOrCounty || ""
    ]
      .filter(Boolean)
      .join(", ");
    const line2 = `${cityAndRegion} ${
      result?.address?.postalCode || ""
    }`.trim();
    const address = [
      this.translatedStrings?.churchName,
      line1,
      line2,
      ChurchProductResults.capitalize(result?.address?.country || ""),
      ChurchProductResults.displayPhoneNumber(result),
      this.makeDirectionsLink(result)
    ]
      .filter(Boolean)
      .join("<br>");
    return address;
  }

  makeAltLink(result) {
    const address = result?.address;
    if (address) {
      const churchName = this.translatedStrings?.churchName || "";
      const parsedAddress = [
        address?.street1 || "",
        address?.street2 || "",
        address?.neighborhood || "",
        address?.city || "",
        address?.county || "",
        address?.state || "",
        address?.postalCode || "",
        address?.country || ""
      ]
        .filter(Boolean)
        .join("+");
      const finalAddress = this.isDefault
        ? `${churchName}+${parsedAddress}`
        : address.split(" ").join("+");
      return `https://www.google.com/maps/search/${finalAddress}`;
    } else return "";
  }

  makeCongregationStr(result) {
    const { type = "" } = result;
    let congregationString = "";
    switch (type.includes("YSA") ? "WARD_YSA" : type) {
      case "WARD":
        congregationString = this.translatedStrings?.generalWard || "";
        break;
      case "WARD_YSA":
        congregationString = this.translatedStrings?.ysaWard || "";
        break;
      case "WARD__SINGLE_ADULT":
        congregationString = this.translatedStrings?.saWard || "";
        break;
      default:
        congregationString = this.translatedStrings?.generalWard || "";
        break;
    }
    const langString = result.properties?.Language || "";
    return congregationString
      .replace(/{language}/, langString.toLowerCase())
      .replace(/{Language}/, langString);
  }

  makeDirectionsLink(result) {
    // prettier-ignore
    return `
    <a data-type="directions-link" href="${result.directionsLink || this.makeAltLink(result)}" target="_blank">
      ${this.directionsIcon} ${this.translatedStrings.getDirections}
    </a>`;
  }

  makeDistanceStr(result) {
    const { calculatedDistance } = result;
    const distance =
      this.translatedStrings.distanceUnits === "miles"
        ? (calculatedDistance * 0.000621371192).toFixed(1) // Miles
        : (calculatedDistance / 1000).toFixed(1); // Kilometers
    return `${distance} ${this.translatedStrings.distanceText}`;
  }

  makeDrawer(result, drawerContainer) {
    const opener = drawerContainer.querySelector('[data-type="drawer-opener"]');
    const body = drawerContainer.querySelector('[data-type="drawer-body"]');
    const label = this.makeTriggerLabel(result);
    const triggerId = `cp-${result.id}`;
    opener.dataset.remoteTriggerId = triggerId;
    body.id = triggerId;
    opener.innerHTML = opener.innerHTML.replace(
      placeholders.triggerLabel,
      label
    );
    const form = this.shouldDisplayForm(result) ? this.formTemplate : "";
    body.innerHTML = body.innerHTML.replace(placeholders.form, form);
    const address = this.makeAddressStr(result);
    body.innerHTML = body.innerHTML.replace(placeholders.address, address);
    body.innerHTML = body.innerHTML.replace(
      new RegExp(placeholders.unitId, "g"),
      result.id
    );
    return drawerContainer;
  }

  makeServiceTimeStr(result, fontStyle = "") {
    // HoursCode example "Su 09:00-11:00";
    const hourCode = result?.hours?.primary.code;
    let output = "";
    if (hourCode) {
      try {
        const bcp47 = [this.twoCharLocale, this.countryCode.toUpperCase()];
        // for info on BCP 47 standard https://tools.ietf.org/html/bcp47
        // const bcp47 = ["ja", "JP"]; // Japanese quick testing
        // const bcp47 = ["es", "MX"]; // Mexican Spanish quick testing

        const engDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
        const engShortDay = hourCode.slice(0, 2);
        // April 5, 2020 was a Sunday. We're adding to that to figure out the day of the week.
        const meetingDay = new Date(
          `April ${5 + engDays.indexOf(engShortDay)}, 2020`
        );

        const dayName = new Intl.DateTimeFormat(bcp47, {
          weekday: "long"
        }).format(meetingDay);
        const label = this.translatedStrings.churchServiceTime.replace(
          /{day}/,
          dayName
        );

        const matchDigits = hourCode.match(/\d\d/g);
        const hours = matchDigits[0];
        const minutes = matchDigits[1];
        meetingDay.setHours(hours, minutes);
        const meetingTime = new Intl.DateTimeFormat(bcp47, {
          hour: "numeric",
          minute: "numeric",
          hourCycle: this.hourCycle
        }).format(meetingDay);
        const time = meetingTime || "";

        if (fontStyle === "bold") output = `<b>${label}</b> ${time}`;
        else output = `${label} ${time}`;
      } catch (err) {
        console.error(err);
        output = "";
      }
      return output;
    } else return "";
  }

  makeTriggerLabel(result) {
    // prettier-ignore
    return `
    <div class="${descriptionClass}" data-location-index="${result.locationIndex}" data-church-unit-id="${result.id}">
      <b>${ChurchProductResults.makeUnitNameStr(result)}</b>
      <br>
      ${this.makeCongregationStr(result)}
      <br>
      ${this.makeServiceTimeStr(result)}
      <br>
      ${result.calculatedDistance ? this.makeDistanceStr(result) : ""}
    </div>`;
  }

  static makeUnitNameStr(result) {
    return result.properties?.LocalNames?.[0] || result.name || "";
  }

  processInitialUrl() {
    const unitDiv = this.resultsContainer.querySelector(
      `[data-church-unit-id="${this.initialSelectedUnitParam}"]`
    );
    const drawerOpener = unitDiv?.closest("[aria-expanded]");

    if (drawerOpener?.getAttribute("aria-expanded") === "false") {
      unitDiv.click();
      scrollIntoViewIfNeeded(unitDiv, { behavior: "smooth" });
    } else this.selectUnit(null);

    this.selectedUnitParam = null;
  }

  resetSVGs() {
    this.element
      .querySelectorAll('[data-type="svg"],[data-icon-name]')
      .forEach((el) => {
        el.removeAttribute("initialized");
      });
  }

  render(data = []) {
    if (this.givingMachineVariant) {
      this.resultsContainer.innerHTML = this.renderGmDetails(data);
      // Back button closes side view
      const backButtons = this.resultsContainer.querySelectorAll("[data-back]");
      backButtons.forEach((button) => {
        button.addEventListener("click", () => {
          this.toggleGmDetails();
          this.fireEvent("clicked-map-back-button", false);
        });
      });

      // Add analytics to the button clicks
      this.resultsContainer
        .querySelectorAll("a, button")
        .forEach((clickable) => {
          clickable.addEventListener("click", (e) => {
            recordInteraction(e.target);
          });
        });
    } else if (data.length === 1) {
      this.resultsContainer.innerHTML = this.renderSingleResult(data);
      this.selectUnit(data.id, 0);
    } else if (data.length > 1) {
      this.resultsContainer.innerHTML = this.renderMultipleResults(data);
    }

    this.initNewComponents();
    if (this.initialSelectedUnitParam) this.processInitialUrl();
  }

  renderSingleResult([result]) {
    const div = this.singleResultTemplate.cloneNode(true);

    const form = this.shouldDisplayForm(result) ? this.formTemplate : "";
    div.innerHTML = div.innerHTML.replace(placeholders.form, form);
    if (form) {
      const formEl = div.querySelector("form");
      const outerLayoutEl = formEl.closest(".layout_outer");
      outerLayoutEl.classList.add("layout_relate-32px");

      const innerLayoutEl = formEl.closest(".layout_inner");
      innerLayoutEl.classList.add("layout_narrow");
      innerLayoutEl.classList.add("layout_mobile-narrow");
    }

    div.innerHTML = div.innerHTML.replace(
      new RegExp(placeholders.unitId, "g"),
      result.id
    );

    // prettier-ignore
    const content = `
    <b>${ChurchProductResults.makeUnitNameStr(result)}</b>
    <br>
    ${this.makeAddressStr(result)}
    <br>
    ${this.makeServiceTimeStr(result, "bold")}`;
    div.innerHTML = div.innerHTML.replace(placeholders.content, content);
    return div.innerHTML;
  }

  renderMultipleResults(data) {
    const div = this.multipleResultTemplate.cloneNode(true);
    const containerTemplate = div.querySelector(
      '[data-type="drawers-container"]'
    );
    const drawers = div.querySelector('[data-type="drawers"]');
    data.forEach((result) => {
      const group = this.makeDrawer(result, containerTemplate.cloneNode(true));
      drawers.appendChild(group);
    });
    containerTemplate.remove();
    return div.innerHTML;
  }

  // Render Giving Machine Details
  renderGmDetails(data) {
    // Translations of titles are handled in the index.js because they're the same for each Giving Machine, this handles the actual unique values
    const results = this.gmDetailsTemplate.querySelector(
      "[data-type='church-product-results']"
    );
    const detailsTemplate = this.gmDetailsTemplate.querySelector(
      `.${styles.information}`
    );

    data.forEach((datum) => {
      const entry = this.createGmEntry(datum, detailsTemplate.cloneNode(true));
      results.appendChild(entry);
    });
    detailsTemplate.remove();

    return this.gmDetailsTemplate.innerHTML;
  }

  createGmEntry(entry, template) {
    const entryContainer = template.querySelector(`.${styles.content}`);
    const entryTemplate = template.querySelector(`.${styles.entry}`);
    Object.entries(entry).forEach((datum) => {
      const entryClone = entryTemplate.cloneNode(true);
      const key = datum[0];
      // Social links need extra attention
      const isSocial = key === "facebook" || key === "instagram";
      const newEntry = isSocial
        ? this.createSocialDetail(entry, entryClone, key)
        : this.createGmDetail(datum, entryClone);
      if (newEntry) entryContainer.appendChild(newEntry);
    });
    entryTemplate.remove();
    template.innerHTML = template.innerHTML
      .replace(placeholders.link, this.makeAltLink({ address: entry.address }))
      .replace(placeholders.name, entry.name);
    template.id = entry.id;

    return template;
  }

  createGmDetail(data, template) {
    const key = data[0];
    const value = data[1];
    const title = this.translatedStrings[key];
    if (title) {
      template.innerHTML = template.innerHTML
        .replaceAll(placeholders.title, title) // Title
        .replaceAll(placeholders.details, value); // Details

      return template;
    }
  }

  createSocialDetail(entry, template, social) {
    const label = this.translatedStrings[social];
    if (!label) return;

    let link = this.socialButtonTemplate
      .cloneNode(true)
      .innerHTML.replace(placeholders.socialLabel, `${entry.name} ${label}`)
      .replace(placeholders.socialLink, entry[social]);

    template.innerHTML = template.innerHTML
      .replaceAll(placeholders.title, this.translatedStrings[`${social}Link`]) // Title
      .replace(placeholders.details, link); // Details

    return template;
  }

  selectUnit(id, locationIndex) {
    const searchParams = new URLSearchParams(window.location.search);
    this.selectedUnitParam = id;
    if (id) searchParams.set("unit", id);
    else searchParams.delete("unit");
    window.history.replaceState(
      { location: searchParams.location, unit: id },
      `Visiting unit ${id}`,
      `${window.location.pathname}?${searchParams.toString()}`
    );
    customEvent(this.element, "clicked-unit-result", {
      detail: { data: locationIndex, bubbles: true }
    });
  }

  shouldDisplayForm(result) {
    const alwaysDisplay = this.formDisplay === "always-display";
    const dynamicDisplay = this.formDisplay === "dynamic-display";
    const { id } = result;
    const idIsPartOfCampaign =
      this.cidCookieVal &&
      this.campaignIds.includes(this.cidCookieVal) &&
      id &&
      this.unitIds.includes(id);

    return alwaysDisplay || (dynamicDisplay && idIsPartOfCampaign);
  }
}
